export const tokenName = nftType => {
	switch (nftType) {
		default:
			return "MVUG T03 NFT";
	}
};

export const tokenSymbol = nftType => {
	switch (nftType) {
		default:
			return "T03";
	}
};

export const tokenImage = nftType => {
	switch (nftType) {
		default:
			return "https://gateway.pinata.cloud/ipfs/QmTRbtVjPi5TwCq2Gfy3VLvxPguMVRHD9QoV2KpGnoBqv7";
	}
};

export const tokenAddress = nftType => {
	return process.env.REACT_APP_MVUGT3_DROPERC721_ADDRESS;
};
