const HeaderBlock = () => {

	const scrollTo = sectionID => {
		window.customScrollTo(sectionID);
	};

	return (
		<>
			<div id="home-block">
				<div className="container gx-0">
					<div className="row align-items-center">
						<div className="col-12">
							<div className="content">
								<div className="text-center">
									<h1>METAVEBLEN IS THE MULTIVERSE</h1>
									<h3>
										Fashion DAO Coalescing With Luxury Goods
									</h3>
								</div>
								{/* <p className="mt-5">
									<Button variant="primary" size="lg" onClick={buyNow}>
										Purchase Now
									</Button>
								</p> */}
								{/* <div className="bullet-points">
									<h3 style={{ fontWeight: 500, textAlign: "center" }} className="mb-4">
										Contribute Exclusive
									</h3>
									<div className="row gx-4">
										<div className="col-12 col-md-4" onClick={() => scrollTo("#awards")}>
											<div>
												<img
													src="images/metacafe.jpg"
													style={{ objectFit: "cover", height: "275px", width: "100%" }}
												/>
											</div>
										</div>
										<div className="col-12 col-md-4" onClick={() => scrollTo("#collective")}>
											<div>
												<img
													src="images/porkie.jpg"
													style={{ objectFit: "cover", height: "275px", width: "100%" }}
												/>
											</div>
										</div>
										<div className="col-12 col-md-4" onClick={() => scrollTo("#events")}>
											<div>
												<img
													src="images/adlai.jpg"
													style={{ objectFit: "cover", height: "275px", width: "100%" }}
												/>
											</div>
										</div>
									</div>
								</div> */}
								{/* <div className="social-icons">
									<a href="https://www.facebook.com/theorientalistspirits" target="_blank">
										<img src="images/icon-fb.png" />
									</a>
									<a href="https://www.instagram.com/orientalistspirits/" target="_blank">
										<img src="images/icon-ig.png" />
									</a>
									<a href="https://opensea.io/collection/founders-collective-pass" target="_blank">
										<img src="images/icon-opensea.png" />
									</a>
									<a href="https://twitter.com/orientalist_xyz" target="_blank">
										<img src="images/icon-twitter.png" />
									</a>
								</div> */}
							</div>
						</div>
						{/* <div className="col-12 col-md-5 float-end">
							<img src="images/digital-land.png" />
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default HeaderBlock;
