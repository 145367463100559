import NFTStack from "src/componets/NFTStack";
import { Button } from "react-bootstrap";
import BuyButton from "../BuyButton";

const CollectiveBlock = () => {
	return (
		<>
			<div id="collective-block">
				<div className="container-fluid gx-0">
					<div className="container">
						<div className="text-center"><h1>MVUG Goods</h1></div>
						<div className="row">
							<div className="col-md-6 col-lg-3">
								<a href="https://metaveblen.org/listing/0xC48f0F6615bbAe017ed2d95749B9249a3a4a4674/1" target="_blank"><img src="https://res.cloudinary.com/wfc-cloud/image/fetch/https://ipfs.io/ipfs/QmXipEF1Gjs5HwdDfy2V98vAAkX3kY9v9ikH6AkmV6PJmU" /></a>
								<h2>Earthling Animation</h2>
								<p>ASMVUGX1</p>
							</div>
							<div className="col-md-6 col-lg-3">
								<a href="https://metaveblen.org/listing/0x6b16c230256066aDD890482B9a0Bc9c1dBDDabAb/1"><img src="https://res.cloudinary.com/wfc-cloud/image/fetch/https://ipfs.io/ipfs/QmdkXJZp7pHkc8SNUxnMFGquHXqAiUiCXpRDbALmUzqVmW" /></a>
								<h2>Liquidians</h2>
								<p>LQVM</p>
							</div>
							<div className="col-md-6 col-lg-3">
								<a href="https://metaveblen.org/listing/0x6BBB6Bb878740e79007cE1cB1DAd756c891187C7/1"><img src="https://res.cloudinary.com/wfc-cloud/image/fetch/https://ipfs.io/ipfs/QmSnxiUDRdaSLqDmUBQAa2mJ39NBAjokXsyaLYJajXhGF4" /></a>
								<h2>The Himalayan Skin</h2>
								<p>HLMV.01</p>
							</div>
							<div className="col-md-6 col-lg-3">
								<a href="https://metaveblen.org/listing/0x911b47fE548106366dBD1209EfEe79bA48574Dc2/1" target="_blank"><img src="https://res.cloudinary.com/wfc-cloud/image/fetch/https://ipfs.io/ipfs/QmP5jVwYeamD6zKpKSEQjQdP9gzJfhHSHmR6R9RAYUTPYb" /></a>
								<h2>Gemers</h2>
								<p>GM_MV G1.0</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CollectiveBlock;
