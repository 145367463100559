import BuyButton from "../BuyButton";

const AwardBlock = () => (
	<div id="awards-block">
		<div className="container-fluid gx-0">
			<div className="row gx-0 align-items-center">
				<div className="col-12 col-md-5">
					<div className="brand-video">
						<video autoPlay muted loop playsInline width="100%">
							<source src="images/mvog.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
				<div className="col-12 col-md-7">
					<div className="content">
						<div className="mobile-background-block">
							<h1>
								<span className="block">
									<span className="thin">MVUG NFT</span>
								</span>
								<span className="block">MetaVeblen Universal Goods</span>{" "}
							</h1>
						</div>
						<div className="mobile-regular-block">
							<h3 className="highlighted" style={{ marginBottom: "25px" }}>
								Price 0.60 ETH
							</h3>
							<p>Created by Queen Veblen who uses her LifeStone to bring forth a multiverse dimension for every species with the love for culture and fashion. She honors all with a digital design of a fashion icon bringing peace and poise in Veblen.</p>

							<p>MVUG is the classification of metaveblen universal goods and Lady Queen V has her own creation known as the Himalaya Lizard where she uses extraction of Lizard Skin + Heat Sensory mastery to layer designs of her own original  fashion good.</p>
							<BuyButton type="T03" cost="0.60 ETH" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AwardBlock;
