export default () => (
	<div id="faq-block">
		<div>
			<div className="container">
				<h1>FAQ</h1>
				<div className="accordion accordion-flush" id="faqAccordion">
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingThree">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseThree"
								aria-expanded="false"
								aria-controls="collapseThree"
							>
								What is the return policy?
							</button>
						</h2>
						<div
							id="collapseThree"
							className="accordion-collapse collapse"
							aria-labelledby="headingThree"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">There is no return after purchase of NFTs (Non Fungible Tokens)</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingSix">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseSix"
								aria-expanded="false"
								aria-controls="collapseSix"
							>
								What do I do if I never receive my email?
							</button>
						</h2>
						<div
							id="collapseSix"
							className="accordion-collapse collapse"
							aria-labelledby="headingSix"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								Please email admin@metaveblen.org with the screenshot of your purchase and your query.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingEleven">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseEleven"
								aria-expanded="false"
								aria-controls="collapseEleven"
							>
								When will I receive my order?
							</button>
						</h2>
						<div
							id="collapseEleven"
							className="accordion-collapse collapse"
							aria-labelledby="headingEleven"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								You will receive immediately in your stated  email.
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingSeven">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseSeven"
								aria-expanded="false"
								aria-controls="collapseSeven"
							>
								If I have any query, who can I contact?
							</button>
						</h2>
						<div
							id="collapseSeven"
							className="accordion-collapse collapse"
							aria-labelledby="headingSeven"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								<p>
									Please email admin@metaveblen.org with the screenshot of your purchase and your query.
								</p>
							</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingTwelve">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseTwelve"
								aria-expanded="false"
								aria-controls="collapseTwelve"
							>
								Who and where are the companies in these campaigns?
							</button>
						</h2>
						<div
							id="collapseTwelve"
							className="accordion-collapse collapse"
							aria-labelledby="headingTwelve"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								They are registered in Philippines with existing operations. For more query, please visit their websites and media pages.
							</div>
						</div>
					</div>
					{/* <div className="accordion-item">
						<h2 className="accordion-header" id="headingFive">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseFive"
								aria-expanded="false"
								aria-controls="collapseFive"
							>
								What blockchain is this project on?
							</button>
						</h2>
						<div
							id="collapseFive"
							className="accordion-collapse collapse"
							aria-labelledby="headingFive"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">Ethereum</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingNine">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseNine"
								aria-expanded="false"
								aria-controls="collapseNine"
							>
								What token standard is The Orientalist Founders Collective contract?
							</button>
						</h2>
						<div
							id="collapseNine"
							className="accordion-collapse collapse"
							aria-labelledby="headingNine"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">ERC-721</div>
						</div>
					</div>
					<div className="accordion-item">
						<h2 className="accordion-header" id="headingEight">
							<button
								className="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								data-bs-target="#collapseEight"
								aria-expanded="false"
								aria-controls="collapseEight"
							>
								Who's the team behind The Orientalist Founders Collective Pass?
							</button>
						</h2>
						<div
							id="collapseEight"
							className="accordion-collapse collapse"
							aria-labelledby="headingEight"
							data-bs-parent="#faqAccordion"
						>
							<div className="accordion-body">
								This project is launched by The Orientalist Spirits, founded by Michel Lu.
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
	</div>
);
