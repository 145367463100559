import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { BlockchainContext } from "src/providers/BlockchainProvider";

const Frontend = () => {
	const blockchainInfo = useContext(BlockchainContext);
	const [showErrorMessage, setShowErrorMessage] = useState(false);

	const handleClose = () => setShowErrorMessage(false);

	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-dark">
				<div className="container-fluid">
					<div>
						<a href="#">
							<img src="https://metaveblen.org/assets/images/logo.png" style={{ maxHeight: "76px", marginRight: "20px" }} />
						</a>
					</div>
					<div>
						<img src="images/pbw-logo.png" style={{ maxHeight: "80px" }} />
					</div>
					{/* <button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0 mx-auto align-items-center">
							
							<li className="nav-item mx-2 d-none d-lg-block">
								<a className="navbar-brand page-scroll" href="#home">
									<img src="images/logo.png" />
								</a>
							</li>
							
						</ul>
					</div> */}
				</div>
			</nav>

			<Outlet />

			{/* <footer>
				<div className="container">
					<div className="text-center disclaimer">
						<small>
							Disclaimer
							<br />* Information shared on this website is not intended to be and does not constitute
							financial advice, investment advice, trading advice, or any other advice. The Orientalist
							Spirits encourages you to do your own research before making any purchases.
						</small>
					</div>
				</div>
			</footer> */}

			<Modal show={showErrorMessage} centered backdrop="static" onHide={handleClose}>
				<Modal.Header closeButton>Error!</Modal.Header>
				<Modal.Body>
					<p>Sorry, we are unable to preview your wallet.</p>
					<p>Please access your wallet through your wallet provider (for ex. MetaMask).</p>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Frontend;
