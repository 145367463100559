import { useContext, useEffect, useState, useRef } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { initialiseCreateWallet, createWallet } from "@paperxyz/js-client-sdk";
import { formatNumber, toWeiValue } from "src/helpers/numeric.helper";
import { BlockchainContext } from "src/providers/BlockchainProvider";
import Loading from "./Loading";
import { truncate, validateEmail } from "src/helpers/string.helper";
import {
	getActiveClaimCondition,
	getWalletsClaimBalance,
	verifyClaimMerkleProof,
	claimNFT,
} from "src/plugins/Ethereum";
import { CheckoutWithCard } from "./CheckoutWithCard";
import { tokenAddress, tokenImage, tokenName, tokenSymbol } from "src/helpers/contracts.helper";
import { db } from "src/plugins/Firebase";
import { collection, addDoc } from "firebase/firestore";

const xrate_ETH_USD = 1650;

const ActionButton = ({ title, subTitle, icon, onClick }) => (
	<button type="button" className="connect-button" onClick={onClick}>
		<div className="title">
			{title}
			{subTitle && <span>{subTitle}</span>}
		</div>
		{icon && (
			<div className="connect-button-icon">
				<div className="chakra-aspect-ratio css-1hhf4g7" aria-hidden="true" focusable="false">
					<img alt={title} src={`/images/${icon}`} className="chakra-image css-0" />
				</div>
			</div>
		)}
	</button>
);

const BuyBlock = ({ nftType }) => {
	const blockchainInfo = useContext(BlockchainContext);

	const [usingWalletAddress, setUsingWalletAddress] = useState(null);

	const [step, setStep] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedQty, setSelectedQty] = useState(1);
	const [userClaimablQty, setUserClaimableQty] = useState(5);
	const [connectedWith, setConnecteWith] = useState("walletconnect");
	const [userGivenEmail, setUserGivenEmail] = useState("");
	const [userGivenReferralCode, setUserGivenReferralCode] = useState("");
	const [isUserGivenEmailVerified, setIsUserGivenEmailVerified] = useState(false);

	const [sdkClientSecret, setSdkClientSecret] = useState(null);

	const [allowedToClaim, setAllowedToClaim] = useState(true);
	const [claimedQuantityDetails, setClaimedQuantityDetails] = useState({
		allocatedQuantity: 0,
		claimedQuantity: 0,
	});

	const [contractDetails, setContractDetails] = useState({
		pricePerToken: 0,
	});

	const merkleProofs = useRef([]);

	const { open: openWalletConnect } = useWeb3Modal();
	const { isConnected: isWalletConnected, address: walletConnectedWithAddress } = useAccount();

	useEffect(() => {
		console.log("blockchainInfo", blockchainInfo);
		if (
			parseInt(blockchainInfo.networkId) &&
			parseInt(blockchainInfo.networkId) !== parseInt(process.env.REACT_APP_CHAIN_ID)
		)
			return;

		setIsLoading(true);
		getActiveClaimCondition(nftType)
			.then(claimDetails => {
				setContractDetails({ ...claimDetails });
				if (!claimDetails.pricePerToken) setStep(100);
				else if (claimDetails.quantityLimitPerWallet === 0) setStep(98);
				else setStep(1);
				console.log(claimDetails);
			})
			.catch(e => {
				console.log(e);
				// the claim is not available at the moment
				setStep(100);
			})
			.finally(() => setIsLoading(false));
	}, []);

	useEffect(() => {
		if (
			(blockchainInfo && blockchainInfo.account && (step === 1 || step === 9)) ||
			(usingWalletAddress && blockchainInfo?.account && blockchainInfo.account !== usingWalletAddress)
		) {
			// check if the wallet have valid claim
			// check if there is Merkle root set for claim
			setIsLoading(true);
			validateClaimCondition(contractDetails.merkleRoot ? "merkle" : null)
				.then(async () => {
					const walletClaimBalance = await getWalletsClaimBalance(nftType, blockchainInfo.account);
					const withUpdatedClaimedValues = { allocatedQuantity: 0, claimedQuantity: 0 };
					if (walletClaimBalance.allocatedQty > 0) {
						withUpdatedClaimedValues.allocatedQuantity = walletClaimBalance.allocatedQty;
					}

					if (walletClaimBalance.claimedQty > 0) {
						withUpdatedClaimedValues.claimedQuantity = walletClaimBalance.claimedQty;
					}

					setClaimedQuantityDetails({ ...withUpdatedClaimedValues });

					const claimableQty = Math.min(
						contractDetails.maxClaimableSupply,
						Math.max(
							0,
							(withUpdatedClaimedValues.allocatedQuantity > 0
								? withUpdatedClaimedValues.allocatedQuantity
								: contractDetails.quantityLimitPerWallet) - withUpdatedClaimedValues.claimedQuantity
						)
					);

					if (claimableQty > 0) {
						setUserClaimableQty(claimableQty);
						if (blockchainInfo.connectedVia === "paper") setStep(2);
					} else setStep(98);
				})
				.finally(() => setIsLoading(false));
		}
		//setConnecteWith(blockchainInfo.connectedVia);
		setUsingWalletAddress(blockchainInfo.account);
	}, [blockchainInfo]);

	useEffect(() => {
		if (!blockchainInfo.account) {
			setClaimedQuantityDetails({
				allocatedQuantity: 0,
				claimedQuantity: 0,
			});
		}
	}, [blockchainInfo.account]);

	useEffect(() => {
		if (step == 1 && blockchainInfo.account) blockchainInfo.disconnectFromBlockchain();
	}, [step]);

	useEffect(() => {
		if (
			parseInt(blockchainInfo.networkId) &&
			parseInt(blockchainInfo.networkId) !== parseInt(process.env.REACT_APP_CHAIN_ID)
		) {
			setStep(101);
		} else if (step === 101) {
			blockchainInfo.account ? setStep(2) : setStep(1);
		}
	}, [blockchainInfo]);

	const validateClaimCondition = claimType =>
		new Promise(async (resolve, reject) => {
			if (claimType === "merkle") {
				// get the proofs of current wallet
				fetch("https://trooxnzyw0.execute-api.ap-southeast-1.amazonaws.com/dev/" + blockchainInfo.account)
					.then(response => response.json())
					.then(async data => {
						const proofs = data.proof;
						merkleProofs.current = proofs;

						await verifyClaimMerkleProof(
							nftType,
							contractDetails.conditionId,
							blockchainInfo.account,
							proofs
						);
						setAllowedToClaim(true);

						resolve();
					})
					.catch(e => {
						setStep(99);
						setAllowedToClaim(false);
						reject();
					});
			} else {
				resolve();
			}
		});

	const buyByEth = async () => {
		if (!blockchainInfo.account || !allowedToClaim) {
			console.log("ere", blockchainInfo.account, allowedToClaim);
			return;
		}

		setIsLoading(true);

		try {
			claimNFT(
				nftType,
				blockchainInfo.account,
				blockchainInfo.connectedVia,
				selectedQty,
				contractDetails.currency,
				contractDetails.pricePerToken,
				merkleProofs.current
			)
				.then(() => {
					mintSuccessfull();					
				})
				.catch(async e => {
					console.log(e);
					const insufficient = JSON.stringify(e).search("insufficient");
					const rejected = JSON.stringify(e).search("rejected");
					if (insufficient >= 0) setStep(96);
					else if (rejected < 0) setStep(97);
				})
				.finally(() => setIsLoading(false));
		} catch (e) {
			console.log(e);
			setStep(97);
		}
	};

	const mintSuccessfull = () => {
		try {
			// check if provided referral code, save in db with collection/wallet address/qty
			if (userGivenReferralCode) {
				addDoc(collection(db, "pbw_referrals"), {
					nftType,
					wallet: blockchainInfo.account,
					qtyMinted: selectedQty,
					paymentMethod: "ETH",
					referralCode: userGivenReferralCode
				});
			}
		} catch (e) {}

		setStep(50);
	}

	const buyByCard = async emailVerified => {
		if (!blockchainInfo.account) {
			setStep(1);
			return;
		}

		if (userGivenEmail === "") {
			setStep(3);
			return;
		}

		if (!isUserGivenEmailVerified && !emailVerified) {
			setIsLoading(true);

			initialiseCreateWallet({
				onSuccess: markEmailVerified,
				onError: () => {
					setIsLoading(false);
					setStep(3);
				},
				// onEmailVerificationInitiated: () => {
				// 	setStep(9.5);
				// 	setIsLoading(false);
				// },
			});

			setTimeout(connectWithEmail, 3000);
			return;
		}

		setIsLoading(true);

		const data = {
			quantity: selectedQty,
			currency: contractDetails.currency,
			pricePerToken: contractDetails.pricePerToken,
			pricePerTokenInWei: toWeiValue(contractDetails.pricePerToken).toString(),
			merkleProofs: merkleProofs.current,
			sendToWallet: blockchainInfo.account,
			email: userGivenEmail,
			nftType,
		};

		const options = {
			method: "POST",
			headers: {
				accept: "application/json",
				"content-type": "application/json",
			},
			body: JSON.stringify(data),
		};

		fetch(process.env.REACT_APP_PAPER_SDK_INTENT_URL, options)
			.then(response => response.json())
			.then(response => {
				console.log(response);
				setSdkClientSecret(response.sdkClientSecret);
				setStep(4);
			})
			.catch(err => {
				console.error(err);
				setStep(95);
			})
			.finally(() => setIsLoading(false));
	};

	const selectQty = e => {
		setSelectedQty(parseInt(e.target.value));
	};

	const updateEmail = e => {
		setIsUserGivenEmailVerified(false);

		if (validateEmail(e.target.value)) setUserGivenEmail(e.target.value);
		else setUserGivenEmail("");
	};

	const markEmailVerified = () => {
		setIsUserGivenEmailVerified(true);
		buyByCard(true);
	};

	const updateReferralCode = e => {
		setUserGivenReferralCode(e.target.value);
	};

	const handleConnect = mode => {
		switch (mode) {
			case "metamask":
				blockchainInfo.connectToBlockchain();
				break;
			case "walletconnect":
				openWalletConnect();
				break;
			case "paper":
				initialiseCreateWallet({
					onSuccess: connectedWithEmail,
					onEmailVerificationInitiated: () => console.log("initiated"),
					onError: connectedWithEmail,
					// onEmailVerificationInitiated: () => {
					// 	setStep(9.5);
					// 	setIsLoading(false);
					// },
				});

				setStep(8);
				break;
		}
	};

	const BackButton = ({ goTo = 0 }) => {
		const goBack = e => {
			e.preventDefault();

			if (goTo < 3 && blockchainInfo.connectedVia !== "paper") setUserGivenEmail("");

			if (goTo) setStep(goTo);
			else restart(e);
		};

		return (
			<a href="#" onClick={goBack}>
				← Back
			</a>
		);
	};

	const restart = e => {
		e.preventDefault();
		setIsLoading(true);
		//blockchainInfo.disconnectFromBlockchain();
		setUserGivenEmail("");
		setTimeout(() => {
			setStep(1);
			setIsLoading(false);
		}, 1000);
	};

	const switchNetwork = async () => {
		try {
			await window.ethereum.request({
				method: "wallet_switchEthereumChain",
				params: [{ chainId: "0x" + parseInt(process.env.REACT_APP_CHAIN_ID).toString(16) }],
			});
		} catch (switchError) {
			// This error code indicates that the chain has not been added to MetaMask.
			if (switchError.code === 4902) {
				try {
					await window.ethereum.request({
						method: "wallet_addEthereumChain",
						params: [
							{
								chainId: "0x" + parseInt(process.env.REACT_APP_CHAIN_ID).toString(16),
								rpcUrls: [process.env.REACT_APP_CHAIN_RPC_URL],
								chainName: process.env.REACT_APP_CHAIN_NAME,
								nativeCurrency: {
									name: process.env.REACT_APP_CHAIN_CURRENCY,
									symbol: process.env.REACT_APP_CHAIN_CURRENCY,
									decimals: 18,
								},
								blockExplorerUrls: [process.env.REACT_APP_CHAIN_EXPLORER],
							},
						],
					});
				} catch (addError) {
					// handle "add" error
				}
			}
			// handle other "switch" errors
		}
	};

	const addAssetToWallet = async () => {
		if (!window.ethereum) return;
		try {
			const wasAdded = await window.ethereum.request({
				method: "wallet_watchAsset",
				params: {
					type: "ERC20", // Initially only supports ERC20, but eventually more!
					options: {
						address: tokenAddress(nftType), // The address that the token is at.
						symbol: tokenSymbol(nftType), // A ticker symbol or shorthand, up to 5 chars.
						decimals: 0, // The number of decimals in the token
						image: tokenImage(nftType),
					},
				},
			});
		} catch (error) {
			console.log(error);
		}
	};

	const connectWithEmail = async () => {
		if (userGivenEmail === "") return;

		setIsLoading(true);
		setStep(9);

		try {
			await createWallet({
				chainName: "Ethereum",
				emailAddress: userGivenEmail,
			});
		} catch (e) {
			setStep(97);
			setIsLoading(false);
		}
	};

	const connectedWithEmail = user => {
		console.log(user);

		if (!user.emailAddress || !user.walletAddress) {
			setStep(8);
			return;
		}

		blockchainInfo.connectedWithEmail({
			walletAddress: user.walletAddress,
		});

		setIsUserGivenEmailVerified(true);

		setIsLoading(false);
	};

	return (
		<>
			<div className="buy-block-container">
				<div className="buy-block-header">
					<div className="row">
						<div className="col-5">
							<img src={`/images/${nftType}.jpg`} alt={tokenName(nftType)} />
						</div>
						<div className="col-7">
							<h1>{tokenName(nftType)}</h1>
						</div>
					</div>
					{(contractDetails.pricePerToken > 0 || isLoading) && (
						<>
							<div className="row align-items-center mt-3">
								<div className="col-6">Quantity</div>
								<div className="col-6 col-right-align">
									<Form.Select
										style={{ width: "auto" }}
										onChange={selectQty}
										defaultValue={selectedQty}
									>
										{Array.from({
											length: userClaimablQty,
										}).map((it, index) => (
											<option value={index + 1} key={index}>
												{index + 1}
											</option>
										))}
									</Form.Select>
								</div>
							</div>
							<div className="row align-items-center mt-3">
								<div className="col-6">Item Cost</div>
								<div className="col-6 col-right-align">
									{!contractDetails.pricePerToken ? (
										<>-</>
									) : (
										<h4>{contractDetails.pricePerToken} ETH</h4>
									)}
								</div>
							</div>
							<hr aria-orientation="horizontal"></hr>
							<div className="row align-items-flex-start mt-3">
								<div className="col-6">Subtotal</div>
								<div className="col-6 col-right-align">
									<div>
										<h4>
											{formatNumber(parseFloat(contractDetails.pricePerToken) * selectedQty, 4)}{" "}
											ETH
										</h4>
										<p style={{ color: "#CCC", margin: 0 }}>
											(USD{" "}
											{formatNumber(
												parseFloat(contractDetails.pricePerToken) * selectedQty * xrate_ETH_USD
											)}
											)
										</p>
									</div>
								</div>
							</div>
							<div className="text-center">
								<img src="images/pay-by.png" alt="pay by ether or credit card" />
							</div>
						</>
					)}
				</div>
				<div className="buy-block-content">
					{step === 1 && (
						<>
							<h4>Connect Your Wallet</h4>
							<p>Your purchase will be delivered to this wallet.</p>
							{/* {window.ethereum && (
								<div className="mb-3">
									<ActionButton
										title="MetaMask"
										icon="metamask.svg"
										onClick={() => handleConnect("metamask")}
									/>
								</div>
							)}
							<div className="mb-3 d-none d-sm-block">
								<ActionButton
									title="WalletConnect"
									icon="walletconnect.svg"
									onClick={() => handleConnect("walletconnect")}
								/>
							</div> */}
							<div className="mb-3">
								{isWalletConnected && (
									<Card className="mb-3">
										<Card.Body>
											<Card.Title
												style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}
											>
												<img
													alt={connectedWith}
													src={`/images/${connectedWith}.svg`}
													className="chakra-image css-0"
													style={{ width: "25px", height: "25px" }}
												/>
												Connected with
											</Card.Title>
											<Card.Text>
												{truncate(walletConnectedWithAddress, 11)}{" "}
												<Button onClick={() => handleConnect("walletconnect")}>Edit</Button>
											</Card.Text>
										</Card.Body>
									</Card>
								)}
								<ActionButton
									title={isWalletConnected ? "Continue" : "Click to Connect"}
									icon="walletconnect.svg"
									onClick={() => (isWalletConnected ? setStep(2) : handleConnect("walletconnect"))}
								/>
							</div>
							{/* <div className="mb-3">
								<ConnectButton
									title="Coinbase Wallet"
									icon="coinbase.svg"
									onClick={() => handleConnect("coinbase")}
								/>
							</div> */}
							{!isWalletConnected && (
								<>
									<h4 style={{ marginTop: "2rem" }}>Don't have a wallet?</h4>
									<div className="mb-3">
										<ActionButton
											title="Continue with your Email"
											icon="paper.svg"
											onClick={() => handleConnect("paper")}
										/>
									</div>
								</>
							)}
						</>
					)}
					{step === 2 && (
						<>
							<h4>Got a Referral Code?</h4>
							<div className="mb-5">
								<p>Please enter the code shared by your friend.</p>
								<InputGroup>
									<Form.Control onChange={updateReferralCode} defaultValue={userGivenReferralCode} />
								</InputGroup>
							</div>
							<h4>Select Payment Method</h4>
							{blockchainInfo.connectedVia !== "paper" && (
								<div className="mb-3">
									<ActionButton
										title="Pay with ETH"
										subTitle="on Ethereum"
										onClick={() => buyByEth()}
									/>
								</div>
							)}
							<div className="mb-3">
								<ActionButton title="Pay with Credit Card" onClick={() => buyByCard()} />
							</div>
							{isWalletConnected && (
								<div className="my-5">
									<p>Your purchase will be delivered to this wallet.</p>
									<Card>
										<Card.Body>
											<Card.Title
												style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}
											>
												<img
													alt={connectedWith}
													src={`/images/${connectedWith}.svg`}
													className="chakra-image css-0"
													style={{ width: "25px", height: "25px" }}
												/>{" "}
												{connectedWith === "paper"
													? "Paper Wallet"
													: connectedWith === "metamask"
													? "MetaMask"
													: "Connected with"}
											</Card.Title>
											<Card.Text>{truncate(walletConnectedWithAddress, 11)}</Card.Text>
										</Card.Body>
									</Card>
								</div>
							)}
							<BackButton />
						</>
					)}
					{step === 3 && (
						<>
							<h4>Please provide your email to pay with card.</h4>
							<div className="mb-3">
								<InputGroup hasValidation>
									<Form.Control
										isInvalid={userGivenEmail === ""}
										onChange={updateEmail}
										defaultValue={userGivenEmail}
									/>
								</InputGroup>
							</div>
							<div className="mb-3">
								<ActionButton title="Continue with Payment" onClick={() => buyByCard()} />
							</div>
							<BackButton goTo={2} />
						</>
					)}
					{step === 4 && (
						<>
							{sdkClientSecret ? (
								<CheckoutWithCard
									// Generate a client secret with the Create Checkout SDK Intent API.
									sdkClientSecret={sdkClientSecret}
									onPaymentSuccess={result => {
										mintSuccessfull();
									}}
									onReview={result => console.log(result)}
									onError={error => {
										console.log(error);
										setStep(94);
									}}
									options={{
										colorBackground: "#FFF",
										colorPrimary: "#f9ab1bcc",
										colorText: "#000",
										borderRadius: 30,
									}}
									addToWallet=""
								/>
							) : (
								<p>
									Sorry! Credit Card payment is not available at the moment. Please try again later.
								</p>
							)}
							<p>
								<BackButton goTo={2} />
							</p>
						</>
					)}
					{step === 8 && (
						<>
							<h4>Please provide your email address</h4>
							<div className="mb-3">
								<InputGroup hasValidation>
									<Form.Control
										isInvalid={userGivenEmail === ""}
										onChange={updateEmail}
										defaultValue={userGivenEmail}
									/>
								</InputGroup>
							</div>
							<div className="mb-3">
								<ActionButton title="Connect using this Email" onClick={() => connectWithEmail()} />
							</div>
							<p>
								Please make sure this email address is correct, a new wallet will be created using this
								email address.
							</p>
							<p>
								You can access your wallet at{" "}
								<a href="https://paper.xyz/wallet" target="_blank">
									https://paper.xyz/wallet
								</a>
								. Your NFT will be sent to this wallet after successful purchase.
							</p>
							<BackButton />
						</>
					)}
					{step === 9 && (
						<>
							<h4>Verifying your Email</h4>
							<p>{userGivenEmail}</p>
							<p>
								Please check your inbox, click on the verificaiton link sent in email and wait for a
								while, as we process your request!
							</p>
						</>
					)}
					{step === 9.5 && (
						<>
							<h4>Verifying your Email</h4>
							<p>{userGivenEmail}</p>
							<p>Please check your inbox, and click on the verificaiton link sent in email</p>
							<p>
								<BackButton goTo={2} />
							</p>
						</>
					)}
					{step === 50 && (
						<>
							<h4>Thank You!</h4>
							<div className="my-2">
								<p>
									You have successfully claimed {selectedQty} {tokenName(nftType)}(s).
								</p>
								<p>
									Depending on the Blockchain Traffic it may take upto few minutes before the NFT
									reflects in your wallet.
								</p>
								{blockchainInfo.connectedVia === "paper" ? (
									<p>
										<a href="https://paper.xyz/wallet" target="_blank" className="connect-button">
											View NFT on your Paper Wallet
										</a>
									</p>
								) : (
									window.ethereum && (
										<p>
											<ActionButton
												title="Add NFT"
												subTitle="to MetaMask"
												onClick={() => addAssetToWallet()}
											/>
										</p>
									)
								)}
							</div>
						</>
					)}
					{step === 94 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>
									We are unable to prcess this transaction at the moment. If money has been debited
									from your card, it will be refunded shortly.
								</p>
								<p>Please contact us for any further clarification.</p>
								<p>
									<a href="#" onClick={restart}>
										← Back
									</a>
								</p>
							</div>
						</>
					)}
					{step === 95 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>Not able to process credit card payment at them moment.</p>
								<p>Please reload this page and try again. Please contact us if this issue persists.</p>
								<p>
									<a href="#" onClick={restart}>
										← Back
									</a>
								</p>
							</div>
						</>
					)}
					{step === 96 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>
									Your wallet have insufficient balance to execute the transaction or your wallet
									might not be connected to the Ethereum network.
								</p>
								<p>Please reload this page and try again. Please contact us if this issue persists.</p>
								<p>
									<a href="#" onClick={restart}>
										← Back
									</a>
								</p>
							</div>
						</>
					)}
					{step === 97 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>
									An unexpected error has occurred. This could be due to an insufficient balance to
									execute the transaction or your wallet might not be connected to the Ethereum
									network.
								</p>
								<p>Please reload this page and try again. Please contact us if this issue persists.</p>
								<p>
									<a href="#" onClick={restart}>
										← Back
									</a>
								</p>
							</div>
						</>
					)}
					{step === 98 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>
									There are no more passes left to claim for this wallet address. You have already
									reached the maximum allocation for this wallet address.
								</p>
								<p>
									<a href="#" onClick={() => handleConnect("walletconnect")}>
										← Use another Wallet
									</a>
								</p>
							</div>
						</>
					)}
					{step === 99 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>
									This wallet address has not been whitelisted. Please note, The Founders Collective
									Pass is only available under private sale for the moment. Only pre-whitelisted
									wallets are able to purchase.
								</p>
								<p>
									<a href="#" onClick={restart}>
										← Try with another Wallet
									</a>
								</p>
							</div>
						</>
					)}
					{step === 100 && (
						<>
							<h4>Sorry!</h4>
							<div className="my-2">
								<p>
									Please ensure you have selected "{process.env.REACT_APP_CHAIN_NAME}" as your wallet
									network.
								</p>
								<p>Please reload this page and try again. Please contact us if this issue persists.</p>
							</div>
						</>
					)}
					{step === 101 && (
						<>
							<h4>Wrong Network!!</h4>
							<div className="my-2">
								<p>You have selected wrong network on your wallet. Please switch to Ethereum Network</p>
								{window.ethereum && (
									<ActionButton
										title="Switch Network"
										subTitle="to Ethereum"
										onClick={() => switchNetwork()}
									/>
								)}
							</div>
						</>
					)}
					{step === 102 && (
						<>
							<h4>Down for Maintenance!</h4>
							<div className="my-2">
								<p>
									TheOrientalist.xyz is down for maintenance. We'll be back with purchase option
									shortly.
								</p>
								<p>Please check back again soon.</p>
							</div>
						</>
					)}
				</div>
			</div>
			{(blockchainInfo.processing || isLoading) && (
				<div className="iframePreloader">
					<Loading />
				</div>
			)}
		</>
	);
};

export default BuyBlock;
